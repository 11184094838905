import React, { useRef, useState, useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  LPlan,
  CSearch,
  CBtnList,
  CSectTitle03,
  CRoomDesc,
  CRoomDesc02,
  CSketchList,
  NavFloor,
  CInlineDefinition,
  CSimpleAccordionList,
  CFooterSearch,
  CSimpleSlider,
} from '../../../../components/_index';
import infoChoice from '../../../../utils/info-choice';
import infoGet from '../../../../utils/info-get';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        type="ja"
        data={{
          title: {
            main: 'スイート',
            sub: <>SUITE</>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/stay/floor/suite/kv.png',
              },
              imgSp: {
                src: '/assets/images/stay/floor/suite/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <CSearch formId="global" />
      <section className="l_sect u_hiddenWrap">
        <LWrap>
          <p className="c_sectLead">
            憧れのスイートで、非日常のひとときを。高級感あふれるお部屋が記憶に残るご滞在を叶えます。
          </p>
          <CSectTitle03 title="ロイヤルスイート" />
          <CSimpleSlider
            width="1040px"
            data={[
              {
                img: {
                  src: '/assets/images/stay/floor/suite/img_royalsuite.png',
                  alt: '',
                },
                imgSp: {
                  src: '/assets/images/stay/floor/suite/img_royalsuite__sp.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/floor/suite/img_royalsuite02.png',
                  alt: '',
                },
                imgSp: {
                  src: '/assets/images/stay/floor/suite/img_royalsuite02__sp.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/floor/suite/img_royalsuite03.png',
                  alt: '',
                },
                imgSp: {
                  src: '/assets/images/stay/floor/suite/img_royalsuite03__sp.png',
                  alt: '',
                },
              },
            ]}
          />
          <p className="c_sectLead">
            ロイヤルパークホテルの歴史とともに歩んできた贅をこらしたスイート。
            <br />
            普遍的な価値と美を舞台に、最高の時間をお届けいたします。
          </p>

          <div style={{ maxWidth: '640px', margin: '0 auto' }}>
            <CInlineDefinition
              exClass="u_mb0"
              col={1}
              data={[
                {
                  title: '広さ',
                  text: <>189m²</>,
                },
                {
                  title: '人数',
                  text: '1〜2名',
                },
                {
                  title: 'ベッドサイズ',
                  text: 'W140cm×L200cm×2台',
                },
              ]}
            />
          </div>
          {/* <CRoomDesc02
            left={
              <>
                <CSketchList
                  data={[
                    {
                      img: {
                        src: '/assets/images/stay/floor/suite/img_royalsuite_floor.png',
                        alt: '',
                      },
                    },
                  ]}
                />
              </>
            }
            right={
              <>
                <CInlineDefinition
                  col={1}
                  data={[
                    {
                      title: '広さ',
                      text: <>189m²</>,
                    },
                    {
                      title: '人数',
                      text: '1〜2名',
                    },
                    {
                      title: 'ベッドサイズ',
                      text: 'W140cm×L200cm×2台',
                    },
                  ]}
                />
              </>
            }
          /> */}
        </LWrap>
      </section>
      <section className="u_mbExLarge u_hiddenWrap">
        <LWrap>
          <CSectTitle03 title="タワースイート" />
          <CSimpleSlider
            width="1040px"
            data={[
              {
                img: {
                  src: '/assets/images/stay/floor/suite/img_towersuite.png',
                  alt: '',
                },
                imgSp: {
                  src: '/assets/images/stay/floor/suite/img_towersuite__sp.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/floor/suite/img_towersuite02.png',
                  alt: '',
                },
                imgSp: {
                  src: '/assets/images/stay/floor/suite/img_towersuite02__sp.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/floor/suite/img_towersuite03.png',
                  alt: '',
                },
                imgSp: {
                  src: '/assets/images/stay/floor/suite/img_towersuite03__sp.png',
                  alt: '',
                },
              },
            ]}
          />
          <p className="c_sectLead">
            使いやすさを追求したL字型。2方向の景観をご覧いただけます。リビングルームには52型の液晶テレビをご用意。
            <br />
            Blu-rayのきれいな画像とワイヤレス充電サウンドシステムの澄んだ音楽をお楽しみいただけます。
            <br />
            ゆったりとした空間で、おくつろぎのひと時をお過ごしください。
          </p>
          <CRoomDesc02
            left={
              <>
                <CSketchList
                  data={[
                    {
                      img: {
                        src: '/assets/images/stay/floor/suite/img_towersuite_floor.png',
                        alt: '',
                      },
                    },
                  ]}
                />
              </>
            }
            right={
              <>
                <CInlineDefinition
                  col={1}
                  data={[
                    {
                      title: '広さ',
                      text: <>72m²</>,
                    },
                    {
                      title: '人数',
                      text: '1〜2名',
                    },
                    {
                      title: 'ベッドサイズ',
                      text: (
                        <>
                          W110cm×L200cm×2台
                          <br />
                          (ハリウッドツイン仕様)
                        </>
                      ),
                    },
                  ]}
                />
              </>
            }
          />
        </LWrap>
      </section>
      <section className="u_mbMedium">
        <LWrap>
          <div className="u_mbExLarge">
            <CSectTitle03 title="基本情報" />
            <p className="c_headingLv4 u_fwb">
              チェックイン 15:00 ／<br className="u_sp" /> チェックアウト
              12:00（正午）
            </p>
            <ul
              className="c_noteList u_centerring"
              style={{ width: 'fit-content' }}
            >
              <li>宿泊プランによって異なる場合がございます。</li>
              <li>
                スイート・エグゼクティブフロア限定特典として、ご希望によりチェックアウトを15:00まで無料でご延⻑いたします
                <br />
                （当⽇の状況により、お受けできない場合もございますのであらかじめご了承ください）。
              </li>
            </ul>
          </div>
          <CSimpleAccordionList
            data={[
              {
                title: '客室設備・備品',
                content: (
                  <>
                    <div className="c_bbBox">
                      <p className="">
                        液晶テレビ（地上デジタル放送、BS放送、海外放送）／シモンズベッド／電気ケトル／冷蔵庫／ミニバー／ネスレ
                        ネスプレッソ／シャワー付トイレ／タオル・バスタオル／ナイトウェア／体重計／脱衣籠／イオンドライヤー／無料ミネラルウォーター／セーフティーボックス／拡大鏡／空気清浄機（加湿機能付）／消臭スプレー
                      </p>
                      <dl className="whiteBox">
                        <dt>スイート・エグゼクティブフロア限定</dt>
                        <dd>
                          ボディウォッシュタオル／バスローブ／ワイヤレス充電サウンドシステム（デラックスタイプ以上）／アイロン・アイロンボード（デラックスタイプ以上）
                        </dd>
                      </dl>
                    </div>
                    <div className="c_bbBox">
                      <dl className="definition">
                        <dt>アメニティグッズ</dt>
                        <dd>
                          L’OCCITANE製ボトルアメニティ（シャンプー、コンディショナー、シャワージェル、ボディミルク）／⽯鹸／カミソリ／ブラシ／ハミガキセット／使い捨てスリッパ
                        </dd>
                      </dl>
                      <dl className="whiteBox">
                        <dt>スイート・エグゼクティブフロア限定</dt>
                        <dd>マウスウォッシュ／基礎化粧品セット</dd>
                      </dl>
                      <ul className="c_noteList u_mt20">
                        <li>
                          アメニティグッズの内容は状況により変更になる場合がございます。あらかじめご了承ください。
                        </li>
                      </ul>
                    </div>
                    <div className="c_bbBox u_pb0">
                      <dl className="definition">
                        <dt>通信環境</dt>
                        <dd>電話／モジュラージャック／Wi-Fi無料接続サービス</dd>
                      </dl>
                      <dl className="whiteBox">
                        <dt>スイート・エグゼクティブフロア限定</dt>
                        <dd>有線放送</dd>
                      </dl>
                    </div>
                  </>
                ),
              },
            ]}
          />
          <CSimpleAccordionList
            data={[
              {
                title: '貸出備品',
                content: (
                  <>
                    <div className="c_bbBox u_pb0">
                      <dl className="definition">
                        <dt>寝具</dt>
                        <dd>
                          そばがら枕／低反発枕／抱き枕／毛布／ベッドボード／バスローブ／パジャマ（S、M、Lサイズ）
                        </dd>
                      </dl>
                      <dl className="definition">
                        <dt>電気、照明器具</dt>
                        <dd>
                          加湿器／ズボンプレッサー／Blu-ray・DVDプレーヤー／蛍光灯スタンド／変圧器／延長コード／各種ソケット／ヘッドホン／くるくるドライヤー／ストレートヘアアイロン
                        </dd>
                      </dl>
                      <dl className="definition">
                        <dt>浴室備品</dt>
                        <dd>
                          乳液、化粧水、洗顔料、メイク落としの化粧品セット／コットン／ヘアゴム
                        </dd>
                      </dl>
                      <dl className="definition">
                        <dt>その他貸出備品</dt>
                        <dd>
                          花瓶／将棋／囲碁／アイスノン／体温計／体重計／体脂肪計／着物用敷き／着物掛け（ハンガータイプ）／アイロン・アイロン台／浴槽滑り止めマット／化粧鏡（拡大鏡）／使い捨てマスク／バス椅子・桶
                        </dd>
                      </dl>
                      <dl className="definition u_mb0">
                        <dt>お子様用備品</dt>
                        <dd>
                          詳細は<Link to="/stay/child/">こちら</Link>
                          をご確認ください。
                        </dd>
                      </dl>
                      {/* <ul className="c_noteList">
                        <li>
                          台数に限りがございますので、ご予約をおすすめいたします。
                        </li>
                        <li>
                          上記以外にも貸し出し備品を取り揃えております。詳しくはお問合せください。
                        </li>
                      </ul> */}
                    </div>
                  </>
                ),
              },
            ]}
          />
          <CSimpleAccordionList
            data={[
              {
                title: 'サービス',
                content: (
                  <>
                    <div className="c_bbBox">
                      <ul className="c_circleList u_fs12 innerList">
                        <li>ボイスメッセージサービス</li>
                        <li>
                          <Link to="/private/3mchpxdzfuxz/">
                            ルームサービス
                          </Link>
                        </li>
                        <li>24時間ランドリーサービス</li>
                        <li>クイックチェックアウトサービス</li>
                        <li>電話チェックアウトサービス</li>
                      </ul>
                    </div>

                    <div className="c_bbBox u_pb0">
                      <dl className="definition u_mb0">
                        <dt>バリアフリー</dt>
                        <dl className="definition">
                          <dt>室内用福祉機</dt>
                          <dd>
                            耳の不自由な方のために振動式ドアノックセンサー、アラームセンサーなどをご用意しております。
                          </dd>
                        </dl>
                        <ul className="c_noteList">
                          <li>
                            数に限りがございますので、事前にご確認ください。
                          </li>
                        </ul>
                        <p className="u_mt15 u_mb0">
                          宿泊営業課
                          <br />
                          TEL <a href="tel:0356412958">03-5641-2958</a>
                          <br />
                          （平日 8:00~20:00/土・日・祝日 9:00~18:00）
                          <br />
                          お問合せフォームは
                          <Link
                            to="https://contact.royalparkhotels.co.jp/rph/contact?_ifbs-rph_contact_form=s1_Step1"
                            target="_blank"
                          >
                            こちら
                          </Link>
                        </p>
                      </dl>
                    </div>
                  </>
                ),
              },
            ]}
          />
          <CSimpleAccordionList
            data={[
              {
                title: 'スイート・エグゼクティブフロア限定特典',
                content: (
                  <>
                    <div className="c_bbBox u_pb0">
                      <dl className="definition">
                        <dt>エグゼクティブラウンジのご利用</dt>
                        <dd>
                          チェックイン・アウトなど各種お手続き／ご朝食／ティータイム／カクテルタイム
                        </dd>
                      </dl>
                      <dl className="definition">
                        <dt>館内施設のご優待</dt>
                        <dd>
                          5F ご宿泊者専用ジム 無料／5F LA VILLA Spa by
                          L’OCCITANE Tokyo ご優待／18F ミーティングルーム
                          2時間無料
                        </dd>
                      </dl>
                      <dl className="definition">
                        <dt>アニバーサリープランナー</dt>
                        <dd>
                          ご宿泊日の5日前までご要望を承りますので、お気軽にお申し付けください。
                        </dd>
                      </dl>
                      <dl className="definition">
                        <dt>レイトチェックアウト（15:00まで）</dt>
                        <dd>
                          ※リクエストとして承ります。※当日の状況により、お受けできない場合もございます。また、ご予約のプランによっては、承れない場合がございます。
                        </dd>
                      </dl>
                    </div>
                  </>
                ),
              },
            ]}
          />
          <CBtnList
            data={[
              {
                label: 'エグゼクティブラウンジ',
                link: {
                  href: '/stay/floor/lounge/',
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <NavFloor />
      <LPlan />
      <CBreadCrumb
        data={{
          parent: [
            {
              label: '宿泊',
              path: '/stay/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <CFooterSearch formId="footer" />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
